import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const MerchPage = () => {
	return (
		<Layout>
			<Seo title="Now" />
			<h2>Now</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/Jesse_Rivest_Toadstool_007.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>

			<h3>What am I doing now?</h3>
			<p>
				Updated September 10, 2024.<br />
				(This is a <span className="italic">now page</span> in the style of Derek Sivers.)
			</p>
			<h4>Still looking for a job</h4>
			<p>
				Because of my split-based situtation (between Brazil and my home, Canada), I need some good work.
				What does that mean?
				Well, steady and reliable work, and ideally well-paying work.
				I guess we all want that, eh?
				It's just that it's quite expensive going between countries and maintaining a modest home in one of them;
				also, <span className="bold">my music touring and production is all on hold</span> until my finances are 🎸<span className="italic">back in black</span>🎸.
				My situation and my existing experience sort of squeeze me into one area of work: <span className="italic">software engineering</span>.
				<br />
				That's what I'm looking for: a software engineering role. Believe it or not, I'm actually geekily good at it.
				<br />
				<Link to="/contact/">Get in touch</Link> if you think you can help 😎.
			</p>
			<h4>Learning AdonisJS and TypeScript</h4>
			<p>
				AdonisJS has nothing to do with music, but it looks very exciting and promising for software application development.
				I've used Laravel before and AdonisJS seems almost identical, with the bonus of getting to work in the Node.js ecosystem.
				So, since I'm looking for work as a software engineer, you might understand why I am working on a personal project to learn AdonisJS.
				(I threw in the learning of TypeScript, as well, since it's essentially a superset of JavaScript and it's advantages are worth the small investment.)
				<br />
				Call it upskilling.
			</p>
			<h4>Livestreaming</h4>
			<p>
				<span className="bold">Back to music, which is what this website is about</span>.
				I've been livestreaming weekly and intend to continue.
				It's great for keeping my repertoire nurtured (especially when I'm in Brazil with very few opportunities to play).
				It's also been excellent for analyzing my performances and thinking more developmentally about them.
				I also got to scratch my photography itch by setting up my modern micro-four-thirds camera with one of my vintage Nikon full-frame
				lenses—add some warm lighting and... voilá!
				For the first half of the year, I was seated for livestream performances and it wasn't really working for me.
				Since I always stand to perform live, I've changed my setup and I now stand to play my livestreams.
				<br />
				If you're curious, check out <a href="//www.youtube.com/@jesserivest/live/">Youtube</a> or <a href="//www.ursalive.com/jesserivest">Ursa Live</a> to see if I have any scheduled livestreams upcoming.
				Or check my <a href="//patreon.com/jesserivest/">Patreon</a> and search for <span className="italic">livestream</span>; I'll mention each and every livestream there, of course.
			</p>
			<h4>Sharing my first effort at singing in Portuguese</h4>
			<p>
				I have never learned a Portuguese-language song, ever... until now.
				I've learned <span className="italic">Aqui e Agora</span>, by Gilberto Gil, and I came up with my own little guitar harmony for it.
				I'm working up to recording a video of me performing the song; I'll share that this month (September) through my mailing list, WhatsApp list, and on my Patreon.
			</p>
			<h4>Starting to demo unreleased songs</h4>
			<p>
				Near the beginning of the pandemic I undertook a 30-day writing challenge: 30 minutes per day to come up with a musical idea.
				For me, that was generally a melody and some supporting harmonic ideas.
				Afterward, I began a longer process (couple months, approximaely an hour per session) of developing those ideas into songs.
				Later yet, I went through the 30 songs/ideas and narrowed them down to about 20, then said, <span className="italic">this is a potential album</span>.
				The project has been since referred to as <span className="italic">Challenge</span>, between me and my partner Jane, and it has been left to sit for a long time.
				I am now revisiting the songs, revisiting the reactions/comments I recorded of Jane's first experience of the whole project, and making demos.
				These demos are simple—mostly just voice and guitar—and inherently contain rhythmic information and (hopefully) emotion.
				They allow me to test for length and test the parts of the songs; I can change the whole song into another key and see if it feels better.
				Once these are done, I'd like to share them with some potential collaborators for producing an album.
				I'd really like to work with someone and not do it all alone.
				None of it will be possible without a job or some magical money.
			</p>

		</Layout>
	);
}

export default MerchPage;
